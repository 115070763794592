.pdf-section p {font-family: 'Times New Roman', Times, serif;font-size: 20px;color:#000000;line-height: 1.5;}      
.pdf-section .pos {position: absolute;z-index: 0;left: 0px;top: 0px}
.pdf-section h1,h2,h3,h4,h5,h6,b{color:#000000!important;font-family: 'Times New Roman', Times, serif;font-weight: bold;}
.pdf-section h1{
    line-height: 1.3;
    font-size:27px
}
.pdf-section h3{
    font-size:20px;
    line-height: 1.4;
}
.pdf-section p{color:#000!important}
